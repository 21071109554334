import React from 'react'

// material UI
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const StyledButton = withStyles({
    root: {
        // background: 'linear-gradient(45deg, #cf965b 20%, #22a2dc 60%, #1f499f)',
        backgroundColor: '#A5843A',// 'rgba(0,0,0,0.4)',
        borderRadius: 3,
        minWidth: '180px',
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 15px',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        margin: '10px 20px'
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

export default function GameModePicker(props) {

    const callback = props.callback;
    const user = props.user;

    return (
        <form>
            {user && (
                <React.Fragment>
                    <StyledButton onClick={() => callback(1)}>
                        Select 1 Card
                    </StyledButton>
                    <StyledButton onClick={() => callback(5)}>
                        Select 5 Cards
                    </StyledButton>
                    <StyledButton onClick={() => callback(7)}>
                        Select 7 Cards
                    </StyledButton>
                    <br />
                </React.Fragment>
                
            )}
            {!user && (
                <React.Fragment>
                    <p> To access the Guidance section please login or sign-up.</p>
                </React.Fragment>
            )}
        </form>
    )
}
