import React, { Component } from 'react';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import CustomizedSnackbars from './components/Snackbar'

// Views
import Home from './views/Home'
import About from './views/About'
import Readings from './views/Readings'
import Guide from './views/Guide'
import Browse from './views/Browse'
import Connect from './views/Connect'
import Journal from './views/Journal'
import JournalEntry from './views/JournalEntry';
import ForgotPassword from './views/ForgotPassword';

import Report from './components/Report'

//components
import Navigation from './components/Navigation'

//style
import './App.scss';

// Functions
import { getCurrentProfile, getCurrentUser } from './utils/AppManager'
import { auth, db } from './utils/FirebaseConfig'

export default class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: null,
      snackbarMessage: null,
      snackbarVariant: 'success'
    }

    this.unsubscribe = this.resubscribe();
    this.unsubscribeListener = null;

    // Get user from local storage 
    const rawUser = window.localStorage.getItem('user')
    // rawUser may return as a string of 'undefined' due 
    // to how the local storage retrieval works
    if (rawUser && rawUser !== 'undefined') {
      this.state.user = JSON.parse(rawUser)
    }

    this.checkUserSession();
  }

  setSnackbar = (snackbarMessage, snackbarVariant) => {
    this.setState({ snackbarMessage, snackbarVariant })
  }

  closeSnackbar = () => {
    this.setState({ snackbarMessage: '' })
  };

  // checks the user session
  checkUserSession = async () => {
    const { user } = this.state;
    const session = await getCurrentProfile();
    if (!session && user) {
      this.logoutUser();
      console.log('logging out');
    }
  }

  // Logs out the current user
  logoutUser = () => {
    localStorage.removeItem('user');
    auth.signOut();
    this.destroyUpdateListener();
    this.setState({ user: null });
    this.setSnackbar('Signed out', 'success')
  }

  // creates a listener for data updates
  // listens for an update flag and updates the user data from all the other
  // data sources
  initUpdateListener = (user) => {
    // unsubscribing listener if it exists
    this.destroyUpdateListener();

    // setting up the user listener
    this.unsubscribeListener = db.collection("users").doc(user.uid).onSnapshot((doc) => {

      const data = doc.data();
      // check for data, if no data log out user with database error
      if (!data) {
        this.logoutUser();
        return;
      }
      // if document is flagged for update grab data and remove the flag
      this.getUserFromDatabase();
    })
  }

  // destroys the data update listener
  destroyUpdateListener = () => {
    if (this.unsubscribeListener) {
      this.unsubscribeListener = this.unsubscribeListener();
    }
  }

  // retrieves the user data from data sources
  getUserFromDatabase = () => {
    this.setState({ working: true })
    getCurrentUser()
      .then(async (newUser) => {

        // checking session to make sure user is still logged in
        const u = await getCurrentProfile();
        if (!u)
          return;

        newUser.uid = u.uid;
        newUser.email = u.email;

        window.localStorage.setItem('user', JSON.stringify(newUser))
        this.setState({ user: newUser, working: false })
      })
      .catch((err) => {
        console.error(err)
        this.logoutUser()
      });
  }

  // listener for auth state changes
  resubscribe = user => {
    if (this.unsubscribe) {
      this.unsubscribe()
    }

    return auth.onAuthStateChanged(u => {
      if (u) {
        if (user) {
          try {

            window.localStorage.setItem('user', JSON.stringify(user))
            this.setState({ user })
            this.initUpdateListener(u);
            this.getUserFromDatabase();
          } catch (err) {
            console.log(err)
            console.error('An error occured retrieving the userObject and storing it locally.')
            this.logoutUser()
          }
        } else {

          // get the user from the database and 
          // create a listener for event changes
          this.getUserFromDatabase();
          this.initUpdateListener(u);
        }
      } else {
        console.log('No firebase session found in local storage.')
      }
    })
  }

  render() {
    const { user, snackbarMessage, snackbarVariant } = this.state

    return (
      <div>
        <CustomizedSnackbars message={snackbarMessage} variant={snackbarVariant} closeSnackbar={this.closeSnackbar} />
        <Router>
          <div>
            <Navigation
              user={user}
              unsubscribe={this.unsubscribe}
              resubscribe={this.resubscribe}
              logoutUser={this.logoutUser}
              setSnackbar={this.setSnackbar} />
          </div>
          <div>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) =>
                  <Home
                    {...props}
                    user={user}
                    unsubscribe={this.unsubscribe}
                    resubscribe={this.resubscribe}
                    logoutUser={this.logoutUser}
                    setSnackbar={this.setSnackbar}
                  />
                }
              />
              <Route path="/about" render={(props) => <About {...props} />} />
              <Route path="/guidance" render={(props) => <Readings {...props} user={user} unsubscribe={this.unsubscribe}
                resubscribe={this.resubscribe}
                logoutUser={this.logoutUser}
                setSnackbar={this.setSnackbar} />} />
              <Route path="/journal-entry" render={(props) => <JournalEntry {...props} user={user} setSnackbar={this.setSnackbar} />} />

              <Route path="/help">
                <Guide />
              </Route>
              <Route path="/cards">
                <Browse />
              </Route>
              <Route path="/forgot-password">
                <ForgotPassword setSnackbar={this.setSnackbar} />
              </Route>
              <Route path="/connect">
                <Connect />
              </Route>
              <Route path="/journal">
                <Journal user={user} />
              </Route>
              <Route path="/report">
                <Report />
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}
