import firebase from 'firebase'

// const firebaseConfig = {
//   apiKey: "AIzaSyAneQs5vTmEZ11lElZktQknvUU5-idzLlo",
//   authDomain: "soullifephilosophydev.firebaseapp.com",
//   databaseURL: "https://soullifephilosophydev.firebaseio.com",
//   projectId: "soullifephilosophydev",
//   storageBucket: "soullifephilosophydev.appspot.com",
//   messagingSenderId: "431046942993",
//   appId: "1:431046942993:web:9a4f5844214e91efddacb0",
//   measurementId: "G-1LTPX6RCJC"
// };

const firebaseConfig = {
  apiKey: "AIzaSyApnn-ONtjr9TN3aGm2-4FGQUc0MDxfvaI",
  authDomain: "soullife-app.firebaseapp.com",
  databaseURL: "https://soullife-app.firebaseio.com",
  projectId: "soullife-app",
  storageBucket: "soullife-app.appspot.com",
  messagingSenderId: "729673735091",
  appId: "1:729673735091:web:fc01cf5ad186b1ca8489c6",
  measurementId: "G-51NMYSWW4E"
};

const fire = firebase.initializeApp(firebaseConfig)
export default fire

export const db = firebase.firestore()
export const auth = firebase.auth()
