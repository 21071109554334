/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2019
All rights reserved.

END_COPYRIGHT_HEADER */

import { auth, db } from './FirebaseConfig'

/**
 * Checks to see if a user is logged in
 * @returns A promise that will evaluate to the profile (Firebase token) if it exists.
 */
const getCurrentProfile = async () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = auth.onAuthStateChanged(user => {
			unsubscribe()
			resolve(user)
		}, reject)
	})
}

/**
 * Retrieves the user data from firebase
 * @returns A promise that will evaluate to the user data in the firestore database
 */
const getCurrentUser = async () => {
    const currentProfile = await getCurrentProfile()

    if (!currentProfile) {
        return null
    }

    const userDocumentSnapshot = await db.collection('users').doc(currentProfile.uid).get()
    return userDocumentSnapshot.data()
}

export {
	getCurrentProfile,
    getCurrentUser
}

