import React from 'react';
import Modal from '@material-ui/core/Modal';
import PlayingField from './PlayingField'
import FlipMove from 'react-flip-move'

import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

export default function ReadingModal({ reading, index, setReadingModal }) {
  if (!reading) {
    return null
  }

  return (
    <div className='journal-modal'>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={reading ? true : false}
        onClose={() => { setReadingModal(null) }}
      >
        <div className='journal-modal'>
          <IconButton onClick={() => { setReadingModal(null) }} aria-label="delete" size="medium">
              <ClearIcon fontSize="inherit" />
          </IconButton>
          <h3 id="simple-modal-title">
            {`Your Question : `}</h3>
            <h2>{`${reading && (reading.title || 'No Question Given')}`}</h2>
            {reading.description && 
              <p className='text' id="simple-modal-description">
              {`Notes:`}<br />
              {`${reading.description}`}
              </p>
            }
          <div>
            <PlayingField cards={reading.cards} gameOver={true} gameMode={reading.cards.length} journal={true}/>
            {(reading.cards.length + 1) <= reading.cards.length && (
              <div className='deck-container'>
                <FlipMove>
                  {this.renderCards(reading.cards)}
                </FlipMove>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}


                    