import React from 'react';
import {
    Link
} from "react-router-dom";

// Material UI Imports
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// Icons
import HomeIcon from '@material-ui/icons/Home'; // Home
import ImageSearchIcon from '@material-ui/icons/ImageSearch'; // Browse
import HelpIcon from '@material-ui/icons/Help'; // Help
import ImportContactsIcon from '@material-ui/icons/ImportContacts'; // Readings
import AllInclusiveIcon from '@material-ui/icons/AllInclusive'; // About
import BlurOnIcon from '@material-ui/icons/BlurOn'; // Connect
import PostAddIcon from '@material-ui/icons/PostAdd'; // Journal
import InputIcon from '@material-ui/icons/Input';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import MenuIcon from '@material-ui/icons/Menu';

// Components
import SignUpModal from './SignUpModal'
import SignInModal from './SignInModal'

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    white: {
        color: 'white',
    },
    gold: {
        color: '#BD8547',
    },
    fullList: {
        width: 'auto',
    },
    paper: {
        //background: "linear-gradient(45deg, rgb(255, 255, 255), rgb(118, 169, 245), rgb(255, 255, 255))",
        background: '#203884',
        color: 'white'
    }
});

const StyledButton = withStyles({
    root: {
        '&:hover': {
            background: "#A5843A",
        },
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

export default function SwipeableTemporaryDrawer({ unsubscribe, resubscribe, user, location, history, logoutUser, setSnackbar }) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false
    });

    const [signUpModalOpen, setSignUpModal] = React.useState(false)
    const [signInModalOpen, setSignInModal] = React.useState(false)

    const toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [side]: open });
    };

    const getLoggedInOptions = () => {
        if (user) {
            return <List>
                {/* Journal */}
                <ListItem button component={Link} to='/journal'>
                    <ListItemIcon>
                        <PostAddIcon className={classes.white} />
                    </ListItemIcon>
                    <ListItemText primary={'Journal'} />
                </ListItem>
                {/* Sign Out */}
                <ListItem button onClick={() => logoutUser()}>
                    <ListItemIcon>
                        <InputIcon className={classes.white} />
                    </ListItemIcon>
                    <ListItemText primary={'Logout'} />
                </ListItem>
            </List>
        } else {
            return <List>
                {/* Create Account */}
                <ListItem button onClick={() => { setSignUpModal(true) }} >
                    <ListItemIcon>
                        <PersonAddIcon className={classes.gold} />
                    </ListItemIcon>
                    <ListItemText className={classes.gold} primary={'Sign Up'} />
                </ListItem>
                {/* Sign In */}
                <ListItem button onClick={() => { setSignInModal(true) }}>
                    <ListItemIcon>
                        <VpnKeyIcon className={classes.gold} />
                    </ListItemIcon>
                    <ListItemText className={classes.gold} primary={'Login'} />
                </ListItem>
            </List>
        }
    }

    const sideList = side => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            <List>
                {/* Home */}
                <ListItem button component={Link} to='/'>
                    <ListItemIcon>
                        <HomeIcon className={classes.white} />
                    </ListItemIcon>
                    <ListItemText primary={'Home'} />
                </ListItem>
                {/* Guide - How to use this app */}
                <ListItem button component={Link} to='/help'>
                    <ListItemIcon>
                        <HelpIcon className={classes.white} />
                    </ListItemIcon>
                    <ListItemText primary={'Help'} />
                </ListItem>
                {/* Readings - Selects cards */}
                <ListItem button component={Link} to='/guidance'>
                    <ListItemIcon>
                        <ImportContactsIcon className={classes.white} />
                    </ListItemIcon>
                    <ListItemText primary={'Guidance'} />
                </ListItem>
                {/* Browse - Browse all cards */}
                <ListItem button component={Link} to='/cards'>
                    <ListItemIcon>
                        <ImageSearchIcon className={classes.white} />
                    </ListItemIcon>
                    <ListItemText primary={'Cards'} />
                </ListItem>
                {/* About - About the app and website */}
                <ListItem button component={Link} to='/about'>
                    <ListItemIcon>
                        <AllInclusiveIcon className={classes.white} />
                    </ListItemIcon>
                    <ListItemText primary={'About'} />
                </ListItem>
                {/* Connect - Link to Toni Reilly website */}
                <ListItem button onClick={() => window.location.href = 'https://tonireillyinstitute.com/'}>
                    <ListItemIcon>
                        <BlurOnIcon className={classes.white} />
                    </ListItemIcon>
                    <ListItemText primary={'Connect'} />
                </ListItem>
            </List>
            <Divider />
            {/* This section is for Authenticated users */}
            {getLoggedInOptions()}
        </div>
    );

    return (
        <div>
            <div className='sidebar'>
                <StyledButton onClick={toggleDrawer('left', true)}>
                    <MenuIcon />
                </StyledButton>
                <StyledButton className='homebuttons' component={Link} to='/'>
                    <HomeIcon />
                </StyledButton>

                <SignUpModal
                    open={signUpModalOpen}
                    setSignUpModal={setSignUpModal}
                    unsubscribe={unsubscribe}
                    resubscribe={resubscribe}
                    setSnackbar={setSnackbar}
                />
                <SignInModal
                    open={signInModalOpen}
                    setSignInModal={setSignInModal}
                    unsubscribe={unsubscribe}
                    resubscribe={resubscribe}
                    setSnackbar={setSnackbar}
                />
                <SwipeableDrawer
                    open={state.left}
                    onClose={toggleDrawer('left', false)}
                    onOpen={toggleDrawer('left', true)}
                    classes={{ paper: classes.paper }}
                >
                    {sideList('left')}
                </SwipeableDrawer>
            </div >
        </div>
    );
}