import React from 'react';
import ReactCardFlip from 'react-card-flip';
import CardBack from '../images/card-back.png';
import CardModal from './CardModal';

function Card(props) {

    const [isFlipped, setIsFlipped] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);

    let style = 'card-img grow ';

    // TODO Make single card show up bigger on phone

    function handleClick() {
        if (props.static)
            return;

        if (props.modal && isFlipped) {
            if (props.setCurrentOpenModal) {
                props.setCurrentOpenModal(props.card)
            } else {
                setModalOpen(true);
            }
        }

        if (props.handleClick)
            props.handleClick(props.index);
        setIsFlipped(true);
    };

    if (props.selected)
        style = 'card-img selected';
    if (props.flipped) {
        setTimeout(() => setIsFlipped(true), 1000);
    }

    if (props.gameMode === 1) {
        style = style + ' bigger'
    }


    return (
        <React.Fragment>
            <ReactCardFlip isFlipped={isFlipped} infinite>
                <div className={style}>
                    <img
                        src={CardBack}
                        alt=''
                        onClick={() => handleClick()}
                    />
                </div>

                <div className={style}>
                    <img
                        src={`/images/cards/${props.number}-${props.category.toUpperCase()}--${props.name}.jpg`}
                        alt=''
                        onClick={() => handleClick()}
                    />
                </div>
            </ReactCardFlip>
            {props.modal && (
                <CardModal
                    open={modalOpen}
                    card={props.modal}
                    onClose={() => setModalOpen(false)}
                />
            )}
        </React.Fragment>
    );
}

// Card.PropTypes = {

// }

export default Card