import React, { Component } from 'react';
import {
  Link
} from "react-router-dom";
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import Modal from '@material-ui/core/Modal';
import SignInForm from './SignInForm'

const StyledButton = withStyles({
  root: {
    //background: 'linear-gradient(45deg, #cf965b 20%, #22a2dc 60%, #1f499f)',
    backgroundColor: '#A5843A',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 20px',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    width: '80%',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

export default class SimpleModal extends Component {

  handleClose = () => {
    const { setSignInModal } = this.props
    setSignInModal(false);
  }

  render = () => {
    const { open, unsubscribe, resubscribe, setSignInModal, setSnackbar } = this.props

    return (
      <div>
        <div className='center-modal'></div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={this.handleClose}
        >
          <div className='paper'>
            <h2 id="simple-modal-title">Sign In</h2>
            {/* <p id="simple-modal-description">
              Forgot your password?
            </p> */}
            <SignInForm unsubscribe={unsubscribe} resubscribe={resubscribe} setSignInModal={setSignInModal} setSnackbar={setSnackbar} />
            <br />
            <StyledButton onClick={this.handleClose} component={Link} to='/forgot-password'>
              Forgot password?
            </StyledButton>
          </div>
        </Modal>
      </div>
    );
  }
}