import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import { auth } from '../utils/FirebaseConfig';

const StyledButtonBlue = withStyles({
    root: {
        //background: 'linear-gradient(45deg, #cf965b 20%, #22a2dc 60%, #1f499f)',
        backgroundColor: '#203884',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        width: '60%',
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            loading: false
        }
    }

    resetPassword = async () => {
        const { email } = this.state
        const { setSnackbar } = this.props
        await this.setState({ loading: true })

        // Sign In
        try {
            await auth.sendPasswordResetEmail(email)
        } catch (error) {
            setSnackbar(error.message, 'error')
            this.setState({ errorMessage: error.message, loading: false })
            return
        }

        // Success
        setSnackbar('Email sent', 'success')
        await this.setState({ loading: false })
    }


    render = () => {
        const { loading } = this.state
        return (

            <div className='centerme'>
                <h3>Password Reset</h3>
                <p>A password reset email will be sent if an account is active using the provided email address.</p>
                <form noValidate autoComplete="off">
                    <div>
                        <TextField
                            error={false}
                            id="standard-error-helper-text"
                            label="Email"
                            defaultValue=""
                            helperText=""
                            className='textField'
                            margin="normal"
                            onChange={(e) => {
                                this.setState({ email: e.target.value })
                            }}
                        />
                    </div>
                    <StyledButtonBlue disabled={loading || false} color="primary" className='modal-button' onClick={this.resetPassword}>
                        Send Link to Email
                        </StyledButtonBlue>
                </form>
                <br />
                <p>Dont forget to check junk, just in case!</p>
            </div>

        );
    }
}