import React, { Component } from 'react'

//material 
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

//components
import Loader from '../components/Loader';
import PlayingField from '../components/PlayingField';

//database
import { db } from '../utils/FirebaseConfig';
import firebase from 'firebase';


export default class JournalEntry extends Component {
    constructor(props) {
        super(props);

        this.state = {
            journalTitle: '',
            journalNotes: '',
            SelectedCards: [],
            sendingEntry: false,
            submitted: false,
        };
    }

    componentDidMount() {
        const { data } = this.props.location;
        const { history, user } = this.props;

        if (!data || !user) {
            history.push('/guidance');
        } else {
            this.setState({ SelectedCards: data.SelectedCards });
        }
    }

    renderJournalForm = () => {
        const { user } = this.props
        const { journalTitle, journalNotes, sendingEntry, submitted } = this.state;

        if (!user) {
            return (
                <form>
                    <h2>Journal Entry</h2>
                    <p>Login or sign up to enter a journal entry about your reading.</p>
                </form>
            );
        }

        return (
            <form>
                <Loader active={sendingEntry} text={'Saving Entry'} />
                <h2>Journal Entry</h2>
                <TextField
                    error={false}
                    id="standard-error-helper-text"
                    label="What was your question?"
                    className='input-style'
                    fullWidth
                    variant='outlined'
                    value={journalTitle}
                    disabled={submitted}
                    onChange={(e) => {
                        this.setState({ journalTitle: e.target.value })
                    }}
                />
                <TextField
                    id="outlined-multiline-static"
                    label="Notes"
                    multiline
                    fullWidth
                    className='input-style'
                    rows="4"
                    variant="outlined"
                    value={journalNotes}
                    disabled={submitted}
                    onChange={(e) => {
                        this.setState({ journalNotes: e.target.value })
                    }}
                />
                <Button disabled={submitted} onClick={() => this.saveToJournal()}>
                    Save to Journal
                </Button>
            </form>
        )
    }

    saveToJournal = async () => {
        let { user, history, setSnackbar } = this.props;
        let { journalTitle, journalNotes, SelectedCards } = this.state;

        if (!user)
            return;

        if (journalTitle === '') {
            setSnackbar('Please enter a question for your reading.', 'error');
            return;
        }

        this.setState({ sendingEntry: true });
        // constructing the entry
        const entry = {
            date: new Date(),
            title: journalTitle,
            description: journalNotes,
            cards: SelectedCards,
        }

        // sending entry to firebase
        const userDocumentRef = db.collection('users').doc(user.uid);
        await userDocumentRef.update({
            readings: firebase.firestore.FieldValue.arrayUnion(entry)
        })

        this.setState({
            sendingEntry: false,
            submitted: true,
            journalTitle: '',
            journalNotes: '',
        });

        history.push('/journal');
    }

    render() {
        const {SelectedCards} = this.state;
        return (
            <div className='journal-entry header-gradient blue-background'>
                <div className='container'>
                    <h1>Journal Entry</h1>
                    <PlayingField 
                        cards={SelectedCards} 
                        gameMode={SelectedCards.length}
                        gameOver={true}  
                        journal={true}
                    />
                    {this.renderJournalForm()}
                </div>
            </div>
        )
    }
}
