import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Card from '@material-ui/core/Card';

import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

export default function CardModal(props) {

    const selectedCard = props.card;
    if (!selectedCard)
        return (<div></div>);

    const renderCard = (card) => {
        
        // splitting the descriptions into an array based on the dot point
        const descriptions = card.Description.split('•');

        // returns the card inside a modal with a border
        return (
            <Card className='cards-modal'>
                <IconButton className='nav-button close-button' onClick={() => props.onClose()} aria-label="delete" size="medium">
                    <ClearIcon fontSize="inherit" />
                </IconButton>
                {props.nextCardModal && <IconButton className='nav-button prev-button' edge="end" onClick={() => {
                    props.onClose()
                    props.nextCardModal(-1)
                    }} aria-label="delete" size="medium">
                    <ArrowBackIcon fontSize="inherit" />
                </IconButton>}
                {props.nextCardModal && <IconButton className='nav-button next-button' edge="end" onClick={() => {
                    props.onClose()
                    props.nextCardModal(1)
                    }} aria-label="delete" size="medium">
                    <ArrowForwardIcon fontSize="inherit" />
                </IconButton>}
                <div className='cardBorder'>
                    <img
                        src={`/images/cards/${card.Number}-${card.Category.toUpperCase()}--${card.Name}.jpg`}
                        alt=''
                    />
                </div>
                <p className={`title ${card.Category}`}>
                    <b>{card.Number} {card.Category}</b>| {card.Name.toUpperCase()}
                </p>
                {descriptions.map((desc, i) => {

                    //styles the description based on the array index of the description
                    let style = '';
                    if (i === 0) style = 'italic';

                    return <p key={i} className={style}>{i > 0 && '•'} {desc}</p>
                })}
            </Card> 

        )
    }

    return (
        <div>
            <Dialog onClose={() => props.onClose()} aria-labelledby="simple-dialog-title" open={props.open}>
                {renderCard(selectedCard)}
            </Dialog>
        </div>
    )
}
