import React, { Component } from 'react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import ReadingModal from './ReadingModal'

const months = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December'
}

export default class JournalGrid extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentReading: null
    }
  }

  setReadingModal = newReading => {
    this.setState({ currentReading: newReading });
  };


  getTimeFormattedReadings = readings => {
    const timeFormattedReadings = {}

    readings.forEach(reading => {
      const date = new Date(reading.date.seconds * 1000); // Convert seconds to milliseconds
      const year = date.getFullYear()
      const monthNumber = date.getMonth()
      if (!timeFormattedReadings[year]) {
        timeFormattedReadings[year] = {}
      }
      if (!timeFormattedReadings[year][monthNumber]) {
        timeFormattedReadings[year][monthNumber] = []
      }
      timeFormattedReadings[year][monthNumber].push(reading)
    })

    return timeFormattedReadings

  }

  render() {
    const { user } = this.props
    const { currentReading } = this.state

    if (!user) {
      return (
        <div className='root'>
        </div>
      )
    }

    const timeFormattedReadings = this.getTimeFormattedReadings(user.readings)
    console.log(user.readings)

    if (user.readings.length === 0) {
      return (
        <div className='header-gradient standard-page more-margin'>
          <p>There is nothing in your journal yet. To save to your journal, perform a reading and press 'save to journal'.</p>
        </div>
      )
    }

    return (
      <div className='header-gradient standard-page'>
        <ReadingModal reading={currentReading} setReadingModal={this.setReadingModal} />

        {/* Loop for each year */}
        {Object.keys(timeFormattedReadings).slice(0).reverse().map((year, yearIndex) => (
          <div key={yearIndex}>
            <h2>{year}</h2>
            {/* Loop for each month in a year */}
            {Object.keys(timeFormattedReadings[year]).slice(0).reverse().map((month, i) => (
              <div key={`[${i}]Subheader-${year}-${month}`} className='root journal-month'>

                <GridList cellHeight={180} className='journalGridList'>
                  <GridListTile cols={2} style={{ height: 'auto' }}>
                    <ListSubheader component="h3">{months[month]}</ListSubheader>
                  </GridListTile>

                  {/* Loop for each reading in a month */}
                  {timeFormattedReadings[year][month].slice(0).reverse().map((reading, index) => (
                    <GridListTile onClick={() => { this.setReadingModal(reading) }} key={`[${index}]/images/cards/${reading.cards[0].Number}-${reading.cards[0].Category.toUpperCase()}--${reading.cards[0].Name}.jpg`}>
                      <img src={`/images/cards/${reading.cards[0].Number}-${reading.cards[0].Category.toUpperCase()}--${reading.cards[0].Name}.jpg`} alt={reading.cards[0].Name} />
                      <GridListTileBar
                        title={reading.title || '[No Title]'}
                        subtitle={new Date(reading.date.seconds * 1000).toDateString() || '[No Date]'}
                      />
                    </GridListTile>
                  ))}
                </GridList>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}