import React from 'react'
import Card from './Card'
import FlipMove from 'react-flip-move'
import CardModal from './CardModal';

export default function PlayingField(props) {

    let { gameMode, gameOver, cards, journal } = props;

    const [currentOpenModal, setCurrentOpenModal] = React.useState(null)

    function nextCardModal(increment) {
        if (!increment) {
            throw new Error('Unspecified increment')
        }
        function mod(n, m) {
            return ((n % m) + m) % m;
        }
        const currentIndex = cards.indexOf(currentOpenModal)
        const newIndex = mod((currentIndex + increment), gameMode)
        console.log({ newIndex })
        setCurrentOpenModal(cards[newIndex])
    }

    if (gameOver) {

        let cardStyles = [''];
        let cardTitles = [''];

        if (gameMode === 1) {
            let cardStyles = ['single'];
        }

        if (gameMode === 5) {
            cardStyles = ['work', 'home', 'outside', 'outcome', 'you'];
            cardTitles = ['1 Work', '2 Home', '3 Outside Influence', '5 Outcome', '4 You'];
        }
        if (gameMode === 7) {
            cardStyles = ['past', 'outcome', 'now', 'you', 'months', 'outside', 'answer',];
            cardTitles = ['1 Past', '6 Outcome', '2 Now', '5 You', ' 3 In Three Months', '4 Outside Influence', '7 Answer'];
        }

        let flipped = false;
        if (journal) flipped = true;

        let bigStyle = ''
        if (gameMode === 1) {
            bigStyle = 'bigger'
        }

        return (
            <React.Fragment>
                {!journal && <h2>Tap the card to reveal its meaning.</h2>}
                {journal && <h2 className='info'>Tap the card to reveal it's meaning.</h2>}
                <div className={`card-field-over gamemode-${gameMode} ${bigStyle}`}>
                    <FlipMove>
                        {
                            cards.map((card, i) => {
                                return (
                                    <div className={`field-card ${cardStyles[i]} selected-grow`} key={card.ID}>
                                        <p className='position-title'>{cardTitles[i]}</p>
                                        <Card
                                            name={card.Name}
                                            category={card.Category}
                                            number={card.Number}
                                            selected={true}
                                            index={i}
                                            card={card}
                                            setCurrentOpenModal={setCurrentOpenModal}
                                            modal={card}
                                            flipped={flipped}
                                            gameMode={gameMode}
                                        />
                                    </div>
                                )
                            })
                        }
                    </FlipMove>

                    <CardModal
                        open={true}
                        card={currentOpenModal}
                        onClose={() => setCurrentOpenModal(null)}
                        nextCardModal={nextCardModal}
                    />
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className={`card-field-playing gamemode-${gameMode}`}>
                <FlipMove>
                    {
                        cards.map((card, i) => {
                            return (
                                <div className='field-card-container' key={card.ID}>
                                    <Card
                                        name={card.Name}
                                        category={card.Category}
                                        number={card.Number}
                                        index={i}
                                        static={true}
                                    />
                                </div>
                            )
                        })
                    }
                </FlipMove>
            </div>
        </React.Fragment>
    )
}

