import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import { auth } from '../utils/FirebaseConfig';

const StyledButtonBlue = withStyles({
  root: {
    //background: 'linear-gradient(45deg, #cf965b 20%, #22a2dc 60%, #1f499f)',
    backgroundColor: '#203884',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    width: '80%',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

export default class ValidationTextFields extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      loading: false
    }
  }

  validate = () => {
    const { email, password } = this.state
    return email && password // Improve this validation condition
  }

  handleSignIn = async () => {
    const { email, password } = this.state
    const { unsubscribe, resubscribe, setSignInModal, setSnackbar } = this.props
    await this.setState({ loading: true })

    // Validate Fields
    if (!this.validate()) {
      this.setState({ loading: false })
      return
    }

    // Sign In
    unsubscribe()
    try {
      await auth.signInWithEmailAndPassword(email, password)
    } catch (error) {
      setSnackbar(error.message, 'error')
      this.setState({ errorMessage: error.message, loading: false })
      return
    }

    // Success
    setSnackbar('Signed in', 'success')
    resubscribe()
    setSignInModal(false)
    await this.setState({ loading: false })
  }

  render = () => {
    const { loading } = this.state
    return (
      <div className='modal auth-modal'>
        <form className='container' noValidate autoComplete="off">
          <div className={{ marginBottom: '100px'}}>
            <TextField
              error={false}
              id="standard-error-helper-text"
              label="Email"
              defaultValue=""
              helperText=""
              className='text-input'
              //margin="normal"
              onChange={(e) => {
                this.setState({ email: e.target.value })
              }}
            />
            <TextField
              id="standard-password-input"
              label="Password"
              className='text-input'
              type="password"
              autoComplete="current-password"
              //margin="normal"
              onChange={(e) => {
                this.setState({ password: e.target.value })
              }}
            />
          </div>
          <br />
          <br />
          <StyledButtonBlue disabled={loading || false} color="primary" className='modal-button' onClick={this.handleSignIn}>
            Sign In
          </StyledButtonBlue>
        </form>
      </div>
    );
  }
}