import React from 'react';
import {
    Link
} from "react-router-dom";
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Carousel from '../components/Carousel'

const StyledButton = withStyles({
    root: {
        backgroundColor: '#A5843A',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        // marginLeft: '20px',
        // marginRight: '20px',
        //marginBottom: '20px', 
        width: '50%',
        marginLeft: '25%',
        marginRight: '25%'
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

function Guide() {
    return (
            <div className='header-gradient standard-page'>
                <h1>Help</h1>
                <div className='paragraph-centred'>
                    <h2>About the Cards</h2>
                    <p>
                        The deck features 78 cards.
                    </p>
                </div>
                <div className='paragraph-centred'>
                    <h3>○ Life Cards</h3>
                    <p>
                        <b>22 cards</b> representing the cycle of life. <br />
                        <b>0 to 21</b> starting with Innocence and ending with Acceptance.
                    </p>

                    <h3>◊ Purpose Cards</h3>
                    <p>
                        <b>40 cards as 4 sets of 10</b>, representing key aspects of life.<br />
                        Numbered <b>1-10</b>, each aspect highlights a process or life experience.<br /><br />
                        <b>Action</b> - Drive and Action<br />
                        <b>Mind</b> - Emotion and Feelings<br />
                        <b>Emotion</b> - Thinking and Intellect<br />
                        <b>Practical</b> - Finances and Material<br />
                    </p>


                    <h3>◊ Trait Cards</h3>
                    <p>
                        <b>16 cards as 4 umbrella persona’s</b> for each set of purpose cards.<br />
                        Numbered <b>11-14</b>, they represent people, and their innate character, personality type and physical traits.<br /><br />
                        <b>Fire</b> - Active Achiever Types<br />
                        <b>Air</b> - Deep Thinking Clever Types<br />
                        <b>Water</b> - Emotionally Driven Types<br />
                        <b>Earth</b> - Providers and Nurturing Types<br />
                    </p>
                </div>
                <div className='paragraph-centred'>
                    <p>View the full <b>How to Use</b> guide below</p>
                    <div className='carousel'>
                        <Carousel />
                    </div>
                    <p>or <b>Browse</b> the gallery</p>
                    <StyledButton component={Link} to='/cards'>
                        Browse the Cards
                    </StyledButton>
                </div>
                <br />
            </div>
    );
}

export default Guide