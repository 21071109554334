import React, { Component } from 'react';

import JournalGrid from '../components/JournalGrid'

class Journal extends Component {

    render() {
        const { user } = this.props
        return (
            <div className='Readings header-gradient blue-background'>
                <h1>Journal</h1>
                <div>
                    <JournalGrid user={user} fluid />
                </div>
            </div>
        );
    }
}

export default Journal