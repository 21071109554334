import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import '../css/Loader.scss';

export default function Loader(props) {
    if (!props.active)
        return (<div></div>);

    return (
        <div className='loader'>
            <CircularProgress size={80} />
            <p>{props.text}</p>
        </div>
    )
}
