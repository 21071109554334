import React from 'react';
import styled from 'styled-components'

const StyledDiv = styled.div`
    
`

function Report() {

    return (
        <StyledDiv>
            <p>Hi</p>
        </StyledDiv>
    );
}

export default Report