import React from 'react';
import PropTypes from 'prop-types';
import {
    Link
} from "react-router-dom";
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';

/**
 * Higher Order Component to use in Class Components
 */

const StyledButton = withStyles({
    root: {
        //background: 'linear-gradient(45deg, #cf965b 20%, #22a2dc 60%, #1f499f)',
        backgroundColor: '#A5843A',
        borderRadius: 8,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        width: '100%',
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

const StyledButtonBlue = withStyles({
    root: {
        // background: 'linear-gradient(45deg, #cf965b 20%, #22a2dc 60%, #1f499f)',
        backgroundColor: '#203884',
        borderRadius: 8,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        width: '100%',
        marginTop: '10px',
        '&:hover': {
            background: '#A5843A'
          }
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '90%',
        textAlign: 'center',
        justifyContent: 'center'
    },
    paper: {
        padding: '30px',
        textAlign: 'center',
        backgroundColor: 'red',
    },
});

class GridAccountComponent extends React.Component {

    render() {
        const { classes, user, signIn, signUp, logout } = this.props;
        return (
            <div className={styles.root}>
                <Grid container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '50px' }}>
                    {!user && (
                        <React.Fragment>
                            <Grid item xs>
                                <StyledButtonBlue onClick={() => signIn()}>
                                    Log In
                                </StyledButtonBlue>
                            </Grid>
                            <Grid item xs>
                                <StyledButtonBlue onClick={() => signUp()}>
                                    Sign Up
                                </StyledButtonBlue>
                            </Grid>
                        </React.Fragment>
                    )}
                    {user && (
                        <React.Fragment>
                            <Grid item xs>
                                <StyledButtonBlue component={Link} to='/journal'>
                                    View Journal
                                </StyledButtonBlue>
                            </Grid>
                            <Grid item xs>
                                <StyledButtonBlue onClick={() => logout()}>
                                    Logout
                                </StyledButtonBlue>
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
                <br />
            </div>
        );
    }
}

GridAccountComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GridAccountComponent);