import React from 'react';

function Connect() {
    return (
        <div className='header-gradient'>
            <h2>Connect</h2>
        </div>
    );
}

export default Connect