import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import SignUpForm from './SignUpForm'

export default class SimpleModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false
    }
  }

  handleOpen = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    const { setSignUpModal } = this.props
    setSignUpModal(false);
  }

  render = () => {
    const { open, unsubscribe, resubscribe, setSignUpModal, setSnackbar } = this.props

    return (
      <div>
        <div className='center-modal'></div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={this.handleClose}
        >
          <div className='paper form'>
            <h1 id="simple-modal-title">Create an account</h1>
            <p id="simple-modal-description">
              You'll need to provide your code that was included with your SoulLife Philosophy product <a href="https://tonireillyinstitute.com/">purchase.</a>
            </p>

            <SignUpForm unsubscribe={unsubscribe} resubscribe={resubscribe} setSignUpModal={setSignUpModal} setSnackbar={setSnackbar} />

          </div>
        </Modal>
      </div>
    );
  }
}