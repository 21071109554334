import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import { auth, db } from '../utils/FirebaseConfig'

const StyledButton = withStyles({
  root: {
    //background: 'linear-gradient(45deg, #cf965b 20%, #22a2dc 60%, #1f499f)',
    backgroundColor: '#A5843A',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 20px',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    width: '80%',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

export default class ValidationTextFields extends Component {
  constructor(props) {
      super(props)

      this.state = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        code: '',
        loading: false
      }
  }
  
  validate = () => {
    const { firstName, lastName, email, password, code } = this.state
    return firstName && lastName && email && password && code // Improve this validation condition
  }

  handleSignUp = async () => {
    const { firstName, lastName, email, password, code } = this.state
    const { unsubscribe, resubscribe, setSignUpModal, setSnackbar } = this.props
    await this.setState({ loading: true })

    // Validate Fields
    if (!this.validate()) {
      this.setState({ loading: false })
      return
    }

    console.log(code)

    if ((code === 'SOULBETA') || (code === 'GUIDANCE')) {
      console.log('Code redeemed')
    } else {
      setSnackbar("Purchase code invalid", 'error')
      this.setState({ loading: false })
      return
    }

    // Create firebase account
    unsubscribe()
    let userCredentials
    try {
      userCredentials = await auth.createUserWithEmailAndPassword(email, password)
    } catch (error) {
      setSnackbar(error.message, 'error')
      this.setState({ loading: false })
      return
    }

    // Write user data to database
    try {
        await db.collection('users').doc(userCredentials.user.uid).set({
            firstName,
            lastName,
            readings: []        
        })
    } catch (error) {
      console.log(error)
      setSnackbar('Something went wrong when creating your account. Please try again later', 'error')
      this.setState({ 
        loading: false
      })
    }

    // Success
    setSnackbar('Sucessfully created account','success')
    resubscribe()
    setSignUpModal(false)
    this.setState({ loading: false })
  }

  render = () => {
    const { loading } = this.state
    return (
      <div className='modal'>
        <form className='container' noValidate autoComplete="off">
          <div>
            <TextField
              error={false}
              id="standard-error-helper-text"
              label="First Name"
              defaultValue=""
              helperText=""
              className='textField'
              margin="normal"
              onChange={(e) => {
                this.setState({ firstName: e.target.value })
              }}
            />
            <TextField
              error={false}
              id="standard-error-helper-text"
              label="Last Name"
              defaultValue=""
              helperText=""
              className='textField'
              margin="normal"
              onChange={(e) => {
                this.setState({ lastName: e.target.value })
              }}
            />
            <TextField
              error={false}
              id="standard-error-helper-text"
              label="Email"
              defaultValue=""
              helperText=""
              className='textField'
              margin="normal"
              onChange={(e) => {
                this.setState({ email: e.target.value })
              }}
            />
            <TextField
              id="standard-password-input"
              label="Password"
              className='textField'
              type="password"
              autoComplete="current-password"
              margin="normal"
              onChange={(e) => {
                this.setState({ password: e.target.value })
              }}
            />
            <TextField
              error={false}
              id="standard-error-helper-text"
              label="Purchase Code"
              defaultValue=""
              helperText=""
              className='textField'
              margin="normal"
              onChange={(e) => {
                this.setState({ code: e.target.value })
              }}
            />
          </div>
          {/*<Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={() => {}}
            autoHideDuration={6000}
            onClose={() => {}}
          >
            <MySnackbarContentWrapper
              onClose={() => {}}
              variant="success"
              message="This is a success message!"
            />
          </Snackbar>*/}
          <StyledButton disabled={loading || false} color="primary" className='modal-button' onClick={this.handleSignUp}>
            Sign Up
          </StyledButton>
          
        </form>
      </div>
    );
  }
}