import React, { Component } from 'react';
import logo2 from '../images/SLP-GuidanceCardsLogo.png';

// Components
import SignUpModal from '../components/SignUpModal';
import SignInModal from '../components/SignInModal';
import GameModePicker from '../components/GameModePicker';
import Grid from '../components/Grid';


class Home extends Component {

    constructor(props) {
        super(props)

        this.state = {
            signInModalOpen: false,
            signUpModalOpen: false,
        }
    }

    selectGamemode = (gamemode) => {
        let { history } = this.props;
        history.push({
            pathname: '/guidance',
            data: {
                gamemode: gamemode
            }
        })
    }

    render() {
        const { unsubscribe, resubscribe, logoutUser, setSnackbar } = this.props;
        const { signUpModalOpen, signInModalOpen } = this.state;

        return (
            <div className="App">
                <header className="App-header">
                    <div className='guidance-cards-logo'>
                        <img src={logo2} className="App-logo" alt="logo" />
                    </div>
                    <br />
                    {/* <GameModePicker
                        callback={this.selectGamemode}
                        user={this.props.user}
                    />
                    <br /> */}
                    <div className='gridbox'>
                        <Grid
                            user={this.props.user}
                            signIn={() => this.setState({ signInModalOpen: true })}
                            signUp={() => this.setState({ signUpModalOpen: true })}
                            logout={() => logoutUser()}
                        />
                    </div>
                    <SignUpModal
                        open={signUpModalOpen}
                        setSignUpModal={(open) => this.setState({ signUpModalOpen: open })}
                        unsubscribe={unsubscribe}
                        resubscribe={resubscribe}
                        setSnackbar={setSnackbar}
                    />
                    <SignInModal
                        open={signInModalOpen}
                        setSignInModal={(open) => this.setState({ signInModalOpen: open })}
                        unsubscribe={unsubscribe}
                        resubscribe={resubscribe}
                        setSnackbar={setSnackbar}
                    />
                    <p
                        className='design-brand-footer'
                        onClick={() => window.location.href = 'https://www.neua.com.au'}>
                        Copyright 2020. Toni Reilly Institute.
                </p>
                </header>
            </div >
        );
    }

}

export default Home