import React, { Component } from 'react';
import CardsList from '../data/cards.json';
import CardModal from '../components/CardModal.js';

class Browse extends Component {

    constructor(props) {
        super(props)

        const categories = ['Life', 'Action', 'Emotion', 'Mind', 'Practical'];
        let cardsOrdered = [];

        categories.forEach((category) => {
            cardsOrdered.push(CardsList.filter((item) => item.Category === category));
        })

        this.state = {
            cardOpen: false,
            categories,
            cardsOrdered,
            selectedCard: 0,
        }
    }

    renderCard = (card) => {

    }

    renderCardList = () => {
        const { categories, cardsOrdered } = this.state;

        return categories.map((category, i) => {
            return (
                <div key={i} className={`browse-category-container ${category}`}>
                    <h2>{category}</h2>
                    {
                        cardsOrdered[i].map((card) => {
                            return (
                                <div className='card-container' key={card.ID}>
                                    <div className='card-browse-img browse-grow'>
                                        <img
                                            src={`/images/cards/${card.Number}-${card.Category.toUpperCase()}--${card.Name}.jpg`}
                                            alt=''
                                            onClick={() => this.setState({ cardOpen: true, selectedCard: card })}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>)
        });
    }

    render() {
        const { cardOpen, selectedCard } = this.state;


        return (
            <div className='Readings header-gradient image-background'>
                <h1>Cards</h1>
                <div className='deck-container wide'>
                    {this.renderCardList()}
                </div>
                <CardModal
                    open={cardOpen}
                    card={selectedCard}
                    onClose={() => this.setState({ cardOpen: false })}
                />
            </div>
        );
    }
}

export default Browse