
// packages
import React, { Component } from 'react';
import FlipMove from 'react-flip-move'
import shuffle from 'lodash/shuffle';

// material UI
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

//components
import SignUpModal from '../components/SignUpModal';
import SignInModal from '../components/SignInModal';
import Card from '../components/Card';
import CardsList from '../data/cards.json';
import PlayingField from '../components/PlayingField';
import GameModePicker from '../components/GameModePicker';
import GridAccount from '../components/GridAccount';

const StyledButton = withStyles({
    root: {
        // background: 'linear-gradient(45deg, #cf965b 20%, #22a2dc 60%, #1f499f)',
        backgroundColor: '#A5843A',// 'rgba(0,0,0,0.4)',
        width: '250px',
        maxWidth: '90vw',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        margin: '10px'
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

const StyledButtonBlue = withStyles({
    root: {
        //background: 'linear-gradient(45deg, #cf965b 20%, #22a2dc 60%, #1f499f)',
        backgroundColor: '#203884',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        width: '100%',
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

class Readings extends Component {

    constructor(props) {
        super(props)

        this.state = {
            cardsList: CardsList,
            SelectedCards: [],
            gameMode: 0,
            gameOver: false,
            journalTitle: '',
            journalNotes: '',
            sendingEntry: false,
            submitted: false,
            signInModalOpen: false,
            signUpModalOpen: false,
            shuffling: false,
        }

        this.interval = null;
        this.shuffleCounter = 0;

        let { data } = this.props.location;

        if (data) {
            this.state.gameMode = data.gamemode;
            this.shuffleCounter = 0;
            this.interval = setInterval(this.sortShuffle, 700);
        }
    }

    // sets the gamemode and shuffles the cards
    selectGameMode = (gameMode) => {
        // setting the card shuffle
        clearInterval(this.interval);
        this.shuffleCounter = 0;
        this.interval = setInterval(this.sortShuffle, 700);

        // setting the gamemode
        this.setState({ gameMode })
    }

    // selects a card
    selectCard = (index) => {
        let { cardsList, SelectedCards } = this.state;
        SelectedCards.push(cardsList.splice(index, 1)[0]);
        this.setState({ cardsList, SelectedCards });
        this.updateGameState()
    }

    // renders the unselected cards
    renderCards() {
        const { cardsList } = this.state

        return cardsList.map((card, i) => {
            return (
                <div className='card-container' key={card.ID}>
                    <Card
                        name={card.Name}
                        category={card.Category}
                        number={card.Number}
                        handleClick={this.selectCard}
                        index={i}
                        selected={false}
                    />
                </div>
            );
        });
    }

    // renders the playing field
    renderCardArea() {
        const { gameOver, gameMode, SelectedCards } = this.state

        return (
            <div>
                <PlayingField
                    cards={SelectedCards}
                    gameOver={gameOver}
                    gameMode={gameMode}
                />
                {(SelectedCards.length + 1) <= gameMode && (
                    <div className='deck-container'>
                        <FlipMove>
                            {this.renderCards()}
                        </FlipMove>
                    </div>
                )}
            </div>
        )
    }

    // shuffles the cards
    sortShuffle = () => {
        this.setState({
            cardsList: shuffle(this.state.cardsList),
            shuffling: true
        });

        // reshuffling a set amount of times
        this.shuffleCounter++;
        if (this.shuffleCounter > 3) {
            clearInterval(this.interval);
            this.setState({ shuffling: false })
        }
    }

    // resets the state of the reading
    resetGameState = () => {
        this.setState({ gameMode: 0, SelectedCards: [], gameOver: false })
    }

    // updates the gamestate to game over
    updateGameState = () => {
        const { gameOver, gameMode, SelectedCards } = this.state

        if (gameMode > 0 && (SelectedCards.length) === gameMode && gameOver === false) {
            this.setState({ gameOver: true })
        }
    }

    moveToJournalEntry = () => {
        const { history } = this.props;
        const { SelectedCards } = this.state;

        history.push({
            pathname: '/journal-entry',
            data: {
                SelectedCards
            }
        })
    }

    render() {
        const { gameOver, gameMode } = this.state
        const { signUpModalOpen, signInModalOpen, shuffling } = this.state;
        const { unsubscribe, resubscribe, logoutUser, setSnackbar } = this.props;
        const { user } = this.props

        let style = 'background-image-1'
        let style2 = 'standard-guidance-page'
        if (gameMode > 0) {
            style = 'blue-background'
            style2 = 'standard-page'
        }

        return (
            <div className={`Readings header-gradient ${style} button-area`}>
                <div className={`${style2}`}>
                <h1>Guidance</h1>
                {user && gameMode === 0 && (
                    <div>
                        <p>{`Welcome, ${user.firstName}!`}</p>
                    </div>
                )}
                {console.log(user)}
                {gameMode === 0 && (
                    <div>
                        <GameModePicker callback={this.selectGameMode} user={user} />
                        {/* <div className='gridbox'> */}
                        <GridAccount
                            user={this.props.user}
                            signIn={() => this.setState({ signInModalOpen: true })}
                            signUp={() => this.setState({ signUpModalOpen: true })}
                            logout={() => logoutUser()}
                        />
                        {/* </div> */}
                        <SignUpModal
                            open={signUpModalOpen}
                            setSignUpModal={(open) => this.setState({ signUpModalOpen: open })}
                            unsubscribe={unsubscribe}
                            resubscribe={resubscribe}
                            setSnackbar={setSnackbar}
                        />
                        <SignInModal
                            open={signInModalOpen}
                            setSignInModal={(open) => this.setState({ signInModalOpen: open })}
                            unsubscribe={unsubscribe}
                            resubscribe={resubscribe}
                            setSnackbar={setSnackbar}
                        />
                    </div>
                )}
                {gameMode === 1 && !gameOver && (
                    <h2>{`Pick a card`}</h2>
                )}
                {gameMode > 1 && !gameOver && (
                    <h3>{`Pick ${gameMode} cards`}</h3>
                )}
                {gameMode > 0 && this.renderCardArea()}
                {gameMode > 0 && !gameOver && (
                    <div className='top-right'>
                        <StyledButton onClick={() => this.resetGameState()}>
                            Pick an alternate Spread
                        </StyledButton>
                    </div>
                )}
                {gameOver && (
                    <React.Fragment>
                        <StyledButton onClick={() => this.moveToJournalEntry()}>
                            Add to Journal
                    </StyledButton>
                        <StyledButton onClick={() => this.resetGameState()}>
                            Back
                    </StyledButton>
                        <br />
                        <br />
                    </React.Fragment>
                )}
                </div>
                {shuffling === true && (
                    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                )}
            </div>
        );
    }
}

export default Readings