import React from 'react';
import ToniReillyPhoto from '../images/ToniReillyPhoto.jpg';
import Autograph from '../images/autograph.png';

function About() {
    return (
        <div className='header-gradient standard-page scenic-background'>
            <h1>About</h1>
            <div className='paragraph'>
                <h2>Introduction</h2>
                <p>These cards were a flash idea in the lead up to hosting my
                    Diploma course. I had a bright idea to create an Oracle
                    deck for my students, it would be different from any other
                    Oracle or tarot deck. The images would be photographs.
                    Mainstream stunning photographs of eye-catching shots
                    from mother nature in all her wonder and glory. All the
                    photographs featured were taken in Australia or New
                    Zealand. Eg. The unique flora and fauna featured in
                    Australia and New Zealand, have provided the perfect
                    scenery for the photographs.<br /><br />
                    I found the artist through an unexpected catch-up phone
                    call to my sister-in-law (Sheree), who is a photographer.
                    As synchronicity would have it, when I phoned, she was
                    busting to do something creative. We are both familiar with
                    the energy of tarot cards, and I decided to base my deck
                    on the traditional meaning that I had adapted over the ten
                    years of reading and teaching cards.<br /><br />
                    It was important to me that my deck had the potential to be
                    embraced by anyone, not just those intuitive or spiritually
                    oriented and therefore they can be intuited by all. That is
                    why I call them Philosophy cards; they relate to modern life
                    and our everyday feelings and situations.<br /><br />
                    Sheree’s brief was to find an image from her vast collection
                    expressing my vision of what I wanted each card image to
                    represent. Her photographs easily blended to represent the
                    energy we needed, and associating them was no problem,
                    as we are both lateral thinkers. Looking at a bright yellow
                    sunflower surely means clarity!<br /><br />
                    In a super short time, we had allocated the 78 images to
                    each card. Then Sheree edited the images to fit our card
                    size and I set about naming them. My graphic designer put
                    them all together, and I had them digitally printed with no
                    guide or packaging, just in time to give them to my students
                    in my 2016 course.<br /><br />
                    That was version one, they were not quite right even
                    though they were fabulous during the course. After a few
                    minor alterations were made, and replacing a few of the
                    images, version two is now here and ready for you.<br /><br />
                    I use cards as a tool to develop intuition, this is great for
                    natural progression. Still, when using cards to read for
                    ourselves, it can be difficult to be accurate. This is due to
                    our being so invested in the outcome, we often don’t want
                    to hear what the card is suggesting. I wrote the guidance
                    related to each card to refer to when you don’t trust your
                    own take on the card.<br /><br />
                    I had a lot of fun creating these cards. I hope they are
                    helpful to you whenever you consult them.
                </p>
                <img src={Autograph} alt=''/>
            </div>
            <div className='paragraph-centred'>
                <hr />
                <h2>About the Author</h2>
                <img src={ToniReillyPhoto} alt=''/>
                <p>Toni Reilly founder of the Toni Reilly Institute, is an international bestselling
                    author, speaker, seminar leader and internationally recognised
                    Regression Specialist. She is the creator of SoulLife® Programs,
                    Seminars and Training, guiding thousands of clients worldwide with
                    her unique intuitive approach to personal transformation. She assists
                    people to overcome their emotional bruises and live with purpose,
                    confidence and compassion.
                </p>
                <hr />
                <h2>About the Photographer</h2>
                <p>Sheree Klaproth lives and works in Mackay, QLD Australia with her
                    husband and two children. She is an avid storm chaser, capturing
                    incredible photos and scenes during her travels and in her local
                    environment. You can reach her by email at sheree-23@hotmail.com
                </p>
            </div>
        </div>
    );
}

export default About