import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Images
import image1 from '../images/InterpretingSpreadsSingleCard.jpg'
import image2 from '../images/InterpretingSpreadsFiveCards.jpg'
import image3 from '../images/InterpretingSpreadsSevenCards.jpg'

export default class Carousel extends React.Component {
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <Slider {...settings}>
                <div className='carousel-image'>
                    <img src={image1} alt=''/>
                </div>
                <div className='carousel-image'>
                    <img src={image2} alt=''/>
                </div>
                <div className='carousel-image'>
                    <img src={image3} alt=''/>
                </div>
            </Slider>
        );
    }
}